import React from 'react';
import { makeStyles, Typography, Card, CardContent, Avatar, Box, Grid } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';

const useStyles = makeStyles((theme) => ({
  reviewContainer: {
    marginTop: theme.spacing(4),
    backgroundColor: '#f1f1f1',
  },
  card: {
    maxWidth: 400,
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
    margin: theme.spacing(6), // Add margin for spacing between reviews
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginBottom: theme.spacing(2),
  },
  reviewer: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  ratingContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  ratingText: {
    marginLeft: theme.spacing(1),
  },
}));

const reviews = [
  {
    reviewer: 'Maria Velkov',
    avatar: 'https://example.com/avatar1.jpg',
    rating: 5,
    content: 'I had a fantastic experience at this dental clinic. The staff was friendly, and the service was excellent. Highly recommended!',
  },
  {
    reviewer: 'Alex Bozinovski',
    avatar: 'https://example.com/avatar2.jpg',
    rating: 5,
    content: "I'm extremely satisfied with the dental services provided by this clinic. The dentist was skilled and professional. Great place!",
  },
  {
    reviewer: 'Mike Smith',
    avatar: 'https://example.com/avatar3.jpg',
    rating: 5,
    content: 'This dental clinic exceeded my expectations. The team was attentive, and the quality of care was outstanding. I am very impressed!',
  },
];

const ReviewCard = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={2} className={classes.reviewContainer}>
      {reviews.map((review, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card className={classes.card}>
            <CardContent>
              <Avatar alt={review.reviewer} src={review.avatar} className={classes.avatar} />
              <Typography variant="h6" className={classes.reviewer}>
                {review.reviewer}
              </Typography>
              <Box className={classes.ratingContainer}>
                <Rating value={review.rating} readOnly />
                <Typography variant="body2" color="textSecondary" className={classes.ratingText}>
                  ({review.rating})
                </Typography>
              </Box>
              <Typography variant="body1">{review.content}</Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default ReviewCard;
