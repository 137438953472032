import React from 'react';
import { Typography, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Step1Image from '../assets/contactUs.jpg';
import Step2Image from '../assets/WeWillContactYou.jpg';
import Step3Image from '../assets/confirmAndPay.jpg';
import Step4Image from '../assets/arrival.jpg';
import Step5Image from '../assets/enjoyMacedonia2.jpg';
import Step6Image from '../assets/smile.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: '#f1f1f1',
  },
  step: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  image: {
    width: '100%',
    height: 'auto',
    maxHeight: 400,
    borderRadius: '40%',
    objectFit: 'cover',
  },
  stepText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    color: 'white',
    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
  },
}));

const StepByStepFlow = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h4" align="center" gutterBottom>
        How it works
      </Typography>
      <Grid container spacing={3}>
      <Grid container spacing={3}>
  <Grid item xs={12}>
    <Paper elevation={3} className={classes.step}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={2}>
          <div style={{ position: 'relative' }}>
            <img src={Step1Image} alt="Step 1" className={classes.image} />
            {/* <Typography variant="h6" className={classes.stepText}>
              Fill out the Contact Form
            </Typography> */}
          </div>
          <Typography variant="body1">
            Fill out our application form. Write the requirements and details that are related to your dental problem. Send us the time of your arrival, dental photos or x-rays and if it is possible a report from your dentist.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <div style={{ position: 'relative' }}>
            <img src={Step2Image} alt="Step 2" className={classes.image} />
            {/* <Typography variant="h6" className={classes.stepText}>
              We Will Contact You
            </Typography> */}
          </div>
          <Typography variant="body1">
            We will contact you within 24 hours with possible solutions for your problem, and if you request - the details of accommodation, destinations you want to visit and details about meeting with the dentist.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <div style={{ position: 'relative' }}>
            <img src={Step3Image} alt="Step 3" className={classes.image} />
            {/* <Typography variant="h6" className={classes.stepText}>
              Confirmation and online payment
            </Typography> */}
          </div>
          <Typography variant="body1">
            You can make an online pre-payment and confirmation with a phone call or e-mail. Please let us know if you need to cancel the trip for any reason.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <div style={{ position: 'relative' }}>
            <img src={Step4Image} alt="Step 4" className={classes.image} />
            {/* <Typography variant="h6" className={classes.stepText}>
              Arrival
            </Typography> */}
          </div>
          <Typography variant="body1">
            Welcome to Macedonia! As soon as you land, our representative will be waiting for you and transport you to the hotel.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <div style={{ position: 'relative' }}>
            <img src={Step5Image} alt="Step 4" className={classes.image} />
            {/* <Typography variant="h6" className={classes.stepText}>
              Enjoy Our Magical Country
            </Typography> */}
          </div>
          <Typography variant="body1">
            You will undoubtedly be astonished by our country's variety and uniqueness such as history tours, incredible mountains, rural tourism, and an excellent nightlife!
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
        <div style={{ position: 'relative' }}>
            <img src={Step6Image} alt="Step 4" className={classes.image} />
            {/* <Typography variant="h6" className={classes.stepText}>
              Smile
            </Typography> */}
          </div>
          <Typography variant="body1">
            Smile!
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  </Grid>
</Grid>

</Grid>

    </div>
  );
};

export default StepByStepFlow;
