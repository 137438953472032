import React from 'react';
import { makeStyles, Typography, Button } from '@material-ui/core';
import backgroundImage from '../assets/first_image.jpg';

const useStyles = makeStyles((theme) => ({
  banner: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '400px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: '#ffffff',
  },
  title: {
    fontSize: '36px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    fontSize: '24px',
    marginBottom: theme.spacing(4),
  },
  button: {
    padding: theme.spacing(1, 4),
  },
  content: {
    backgroundColor: '#f1f1f1',
    padding: theme.spacing(4),
    textAlign: 'center',
  },
}));

const LandingPageBanner = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.banner}>
        <Typography variant="h2" component="h1" className={classes.title}>
          Welcome to our Dental Clinic
        </Typography>
        <Typography variant="h4" className={classes.subtitle}>
          Experience Quality Dental Care at Affordable Prices
        </Typography>
        <Button variant="contained" color="primary" className={classes.button}>
          Contact us
        </Button>
      </div>
      <div className={classes.content}>
        <Typography variant="h5" gutterBottom>
          Discover the Difference in Dental Care
        </Typography>
        <Typography variant="body1">
        Many people have already felt the benefits of dental treatment abroad. There are lots of dental tourism destinations worldwide that provide affordable high quality dental care, but Macedonia is quickly becoming a popular choice for traveling in this purpose.
        </Typography>
      </div>
    </>
  );
};

export default LandingPageBanner;
