import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Paper,
  TextField,
  Typography,
  Box,
  makeStyles,
} from '@material-ui/core';
import { FaViber, FaSkype, FaWhatsapp, FaEnvelope, FaCcMastercard, FaCcVisa } from 'react-icons/fa';
import PaymentMethod from './PaymentMethod';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: '100px',
    paddingRight: '100px'
  },
  methods: {
    paddingLeft: '30px',
    paddingRight: '30px',
  }
}))

const ContactForm = () => {

  const classes = useStyles();
  
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');
  const [communicationMethods, setCommunicationMethods] = useState([]);
  const [file, setFile] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [selectedPaymentMethods, setSelectedPaymentMethods] = useState([]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    switch (name) {
      case 'firstName':
        setFirstName(value);
        break;
      case 'lastName':
        setLastName(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'phoneNumber':
        setPhoneNumber(value);
        break;
      case 'message':
        setMessage(value);
        break;
      default:
        break;
    }
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;

    if (checked) {
      setCommunicationMethods((prevMethods) => [...prevMethods, value]);
    } else {
      setCommunicationMethods((prevMethods) =>
        prevMethods.filter((method) => method !== value)
      );
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const selectedFile = event.dataTransfer.files[0];
    setFile(selectedFile);
    setDragging(false);
  };

  const handlePaymentMethodChange = (event) => {
    const { value, checked } = event.target;

    if (checked) {
      setSelectedPaymentMethods((prevMethods) => [...prevMethods, value]);
    } else {
      setSelectedPaymentMethods((prevMethods) =>
        prevMethods.filter((method) => method !== value)
      );
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Handle form submission logic here
    console.log({
      firstName,
      lastName,
      email,
      phoneNumber,
      message,
      communicationMethods,
      file,
    });

    // Reset form fields
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhoneNumber('');
    setMessage('');
    setCommunicationMethods([]);
    setFile(null);
  };

  return (
    <div>
      <Typography variant="h4" align="center" gutterBottom>
        Contact Us
      </Typography>
      <Paper elevation={3} className={classes.root}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="First Name"
                name="firstName"
                value={firstName}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Last Name"
                name="lastName"
                value={lastName}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Email Address"
                name="email"
                value={email}
               onChange={handleInputChange}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Phone Number"
                name="phoneNumber"
                value={phoneNumber}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Message"
                name="message"
                value={message}
                onChange={handleInputChange}
                variant="outlined"
                multiline
                rows={4}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <div
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDragOver={(e) => e.preventDefault()}
                onDrop={handleDrop}
                style={{
                  border: '2px dashed #ccc',
                  borderRadius: '4px',
                  padding: '16px',
                  background: dragging ? '#f9f9f9' : '#fff',
                  marginBottom: '16px',
                }}
              >
                <Typography variant="body1" align="center" style={{ marginBottom: '8px' }}>
                  Drag and drop files here or click to select files
                </Typography>
                {file && <Typography variant="body2">{file.name}</Typography>}
              </div>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset" className={classes.methods}>
                <FormLabel component="legend">Select Communication Methods</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="Viber"
                        checked={communicationMethods.includes('Viber')}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label={
                      <Box display="flex" alignItems="center">
                        <FaViber style={{ marginRight: '8px' }} />
                        Viber
                      </Box>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="Skype"
                        checked={communicationMethods.includes('Skype')}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label={
                      <Box display="flex" alignItems="center">
                        <FaSkype style={{ marginRight: '8px' }} />
                        Skype
                      </Box>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="WhatsApp"
                        checked={communicationMethods.includes('WhatsApp')}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label={
                      <Box display="flex" alignItems="center">
                        <FaWhatsapp style={{ marginRight: '8px' }} />
                        WhatsApp
                      </Box>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="Email"
                        checked={communicationMethods.includes('Email')}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label={
                      <Box display="flex" alignItems="center">
                        <FaEnvelope style={{ marginRight: '8px' }} />
                        Email
                      </Box>
                    }
                  />
                </FormGroup>
              </FormControl>
              <FormControl component="fieldset" className={classes.methods}>
                <FormLabel component="legend">Select Payment Methods</FormLabel>
                <FormGroup>
                  <PaymentMethod
                    method="Mastercard"
                    checked={selectedPaymentMethods.includes('Mastercard')}
                    onChange={handlePaymentMethodChange}
                  />
                  <PaymentMethod
                    method="Visa"
                    checked={selectedPaymentMethods.includes('Visa')}
                    onChange={handlePaymentMethodChange}
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  // Reset form fields
                  setFirstName('');
                  setLastName('');
                  setEmail('');
                  setPhoneNumber('');
                  setMessage('');
                  setCommunicationMethods([]);
                  setFile(null);
                }}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </div>
  );
};

export default ContactForm;
