import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, makeStyles, MenuItem, Menu, IconButton } from '@material-ui/core';
import LanguageIcon from '@material-ui/icons/Language';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: '#3f51b5',
  },
  title: {
    flexGrow: 1,
  },
  menuItem: {
    marginLeft: theme.spacing(2),
  },
}));

const MenuBar = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState('English');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageSelect = (language) => {
    setSelectedLanguage(language);
    handleClose();
  };

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar>
        <Typography variant="h6" className={classes.title}>
          Dental Travel
        </Typography>
        <Button color="inherit" className={classes.menuItem}>
          Dental Clinic
        </Button>
        <Button color="inherit" className={classes.menuItem}>
          Dental Services
        </Button>
        <Button color="inherit" className={classes.menuItem}>
          Prices
        </Button>
        <Button color="inherit" className={classes.menuItem}>
          Contact
        </Button>
        {/* <IconButton color="inherit" onClick={handleClick}>
          <LanguageIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => handleLanguageSelect('English')}>
            <span role="img" aria-label="en">🇺🇸</span> English
          </MenuItem>
          <MenuItem onClick={() => handleLanguageSelect('German')}>
            <span role="img" aria-label="de">🇩🇪</span> German
          </MenuItem>
          <MenuItem onClick={() => handleLanguageSelect('Macedonian')}>
            <span role="img" aria-label="mk">🇲🇰</span> Macedonian
          </MenuItem>
        </Menu> */}
      </Toolbar>
    </AppBar>
  );
};

export default MenuBar;
