import './App.css';
import ContactForm from './component/ContactForm';
import StepByStepFlow from './component/StepByStep';
import MenuBar from './component/MenuBar';
import LandingPageBanner from './component/LandingPageBanner';
import ReviewCard from './component/ReviewCard';
import MeetTheDoctors from './component/MeetTheDoctors';
import WhyChooseUs from './component/WhyChooseUs';
import PricingPlans from './component/PricingPlans';

function App() {
  return (
    <div className="App">
      <MenuBar />
      <LandingPageBanner />
      <PricingPlans />
      <StepByStepFlow />
      <ContactForm />
      <WhyChooseUs />
      <MeetTheDoctors />
      <ReviewCard />
    </div>
  );
}

export default App;
