import React from 'react';
import { makeStyles, Typography, Grid, Card, CardContent } from '@material-ui/core';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

// import Image1 from '../assets/enjoyMacedonia.jpg';
import Image2 from '../assets/bitola.jpg';
import Image3 from '../assets/enjoyMacedonia2.jpg';
// import Image4 from '../assets/ohrid2.jpg';
import Image5 from '../assets/skopje.jpg';

const useStyles = makeStyles((theme) => ({
  sliderContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  imageSlider: {
    width: '100%',
    height: 'auto',
    borderRadius: theme.spacing(1),
    objectFit: 'cover',
  },
  reasonCard: {
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(0),
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: theme.spacing(1),
    '&:first-child': {
      marginTop: theme.spacing(4),
    },
  },
  reasonTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(0),
    fontSize: '15px'
  },
  reasonText: {
    marginBottom: theme.spacing(0),
    fontSize: '10px'
  },
  root: {
    padding: '20px',
    backgroundColor: '#f1f1f1',
  }
}));

const images = [
  // {
  //   src: Image1,
  //   alt: 'Macedonia Image 1',
  // },
  {
    src: Image2,
    alt: 'Macedonia Image 2',
  },
  {
    src: Image3,
    alt: 'Macedonia Image 3',
  },
  // {
  //   src: Image4,
  //   alt: 'Macedonia Image 4',
  // },
  {
    src: Image5,
    alt: 'Macedonia Image 5',
  },
];

const reasons = [
  {
    title: 'The most affordable dental tourism destination',
    text: 'In Macedonia everything is less expensive. Not only the prices for dental services are the lowest in all Europe. You will spend less for food, transportation, accommodation and entertainment as well.',
  },
  {
    title: 'Enjoyable Leisure Activities',
    text: 'Macedonia is good for both Relax and Active pastime. Green parks and forests, museums and theaters, night clubs and bars, the quality wine cellars, monasteries and churches, lakes and mountains… These are just some of the many ways to spend your free time between the visits to the dentist.',
  },
  {
    title: 'Low cost flights',
    text: 'There are numerous low cost flights to Macedonia from many European cities. This means you can save money both on dental treatment and on travel costs.',
  },
  {
    title: 'Distance',
    text: 'In just 2-3 hours of flight you can get to Macedonia practically from any European country.',
  },
  {
    title: 'Hospitable and friendly people',
    text: 'You will feel comfortable in our country as Macedonian people are very welcoming and friendly.',
  },
];

const WhyChooseMacedoniaWidget = () => {
  const classes = useStyles();

  return (
    <div className={classes.root} >
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <div className={classes.sliderContainer}>
            <Slide easing="ease" duration={3000} arrows={false}>
              {images.map((image, index) => (
                <div key={index} className="each-slide">
                  <img src={image.src} alt={image.alt} className={classes.imageSlider} />
                </div>
              ))}
            </Slide>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            {reasons.map((reason, index) => (
              <Grid item xs={4} key={index}>
                <Card className={classes.reasonCard}>
                  <CardContent>
                    <Typography variant="h6" className={classes.reasonTitle}>
                      {reason.title}
                    </Typography>
                    <Typography variant="body1" className={classes.reasonText}>
                      {reason.text}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default WhyChooseMacedoniaWidget;
