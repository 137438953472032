import React from 'react';
import { makeStyles, Typography, Grid, Card, CardContent, List, ListItem, ListItemText, ListItemAvatar, Avatar } from '@material-ui/core';
import all4Implants from '../assets/all4Implants.png';
import all6implants from '../assets/all6implants.png';
import singleImplant from '../assets/singleImplant.png';
import zirconiaCrown from '../assets/zirconiaCrown.png';
import mkFlag from '../assets/mk.png';
import deFlag from '../assets/de.png';
import ukFlag from '../assets/uk.png';

const useStyles = makeStyles((theme) => ({
  pricingContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  pricingPlan: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: theme.spacing(1),
  },
  planTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  planImage: {
    width: '100%',
    height: 200,
    objectFit: 'cover',
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  priceList: {
    width: '100%',
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  countryAvatar: {
    marginRight: theme.spacing(1),
  },
  price: {
    textAlign: 'right',
  },
}));

const pricingPlans = [
  {
    title: 'All on 4 \n Implants',
    image: all4Implants,
    prices: [
      { country: 'Macedonia', price: '€3800', flag: mkFlag },
      { country: 'Germany', price: '€15000', flag: deFlag },
      { country: 'UK', price: '£10000', flag: ukFlag },
    ],
  },
  {
    title: 'All on 6 \n Implantes',
    image: all6implants,
    prices: [
      { country: 'Macedonia', price: '€4800', flag: mkFlag },
      { country: 'Germany', price: '€18500', flag: deFlag },
      { country: 'UK', price: '£15000', flag: ukFlag },
    ],
  },
  {
    title: 'Single implant',
    image: singleImplant,
    prices: [
      { country: 'Macedonia', price: '€500', flag: mkFlag },
      { country: 'Germany', price: '€1800', flag: deFlag },
      { country: 'UK', price: '£2000', flag: ukFlag },
    ],
  },
  {
    title: 'All ceramic (zirconia) crown',
    image: zirconiaCrown,
    prices: [
      { country: 'Macedonia', price: '€200', flag: mkFlag },
      { country: 'Germany', price: '€900', flag: deFlag },
      { country: 'UK', price: '£1300', flag: ukFlag },
    ],
  },
];

const PricingPlans = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={4} className={classes.pricingContainer}>
      {pricingPlans.map((plan, index) => (
        <Grid item xs={12} md={6} lg={3} key={index}>
          <Card className={classes.pricingPlan}>
            <Typography variant="h6" className={classes.planTitle}>
              {plan.title}
            </Typography>
            <img src={plan.image} alt={plan.title} className={classes.planImage} />
            <List className={classes.priceList}>
              {plan.prices.map((price, index) => (
                <ListItem key={index} className={classes.listItem}>
                  <ListItemAvatar>
                    <Avatar src={price.flag} alt={price.country} className={classes.countryAvatar} />
                  </ListItemAvatar>
                  <ListItemText primary={price.country} />
                  <ListItemText primary={price.price} className={classes.price} />
                </ListItem>
              ))}
            </List>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default PricingPlans;
