import React from 'react';
import { makeStyles, Typography, Card, CardContent, CardMedia, Grid, Divider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  doctorsContainer: {
    margin: `${theme.spacing(4)}px 0`,
  },
  doctorCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(1),
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: theme.spacing(1),
  },
  doctorImage: {
    width: '100%',
    height: 'auto',
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  doctorName: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(0.5),
    fontSize: theme.typography.subtitle1.fontSize,
  },
  professionText: {
    marginTop: theme.spacing(0.5),
    color: theme.palette.text.secondary,
    fontSize: theme.typography.caption.fontSize,
  },
}));

const doctors = [
    {
      name: 'Dr. Zlatko Vrshkovski',
      image: 'http://vrshkovski.com.mk/wp-content/uploads/2017/12/REC_6809-480x300.jpg',
      specialty: 'MAXILLOFACIAL SURGEON, DMD',
    },
    {
      name: 'Dr. Petar Vrshkovski',
      image: 'http://vrshkovski.com.mk/wp-content/uploads/2017/12/Dr.-Petar-480x300.jpg',
      specialty: 'ORAL SURGEON, DMD',
    },
  ];

const MeetTheDoctors = () => {
  const classes = useStyles();

  return (
    <div className={classes.doctorsContainer}>
      <Grid container spacing={3} justify="center">
        {doctors.map((doctor, index) => (
          <Grid item xs={12} md={4} key={index}>
            <Card className={classes.doctorCard}>
              <CardMedia
                component="img"
                alt={doctor.name}
                image={doctor.image}
                title={doctor.name}
                className={classes.doctorImage}
              />
              <CardContent>
                <Typography variant="subtitle1" className={classes.doctorName}>
                  {doctor.name}
                </Typography>
                <Divider />
                <Typography variant="caption" className={classes.professionText}>
                  {doctor.specialty}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default MeetTheDoctors;
