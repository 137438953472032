// PaymentMethod.js

import React from 'react';
import { FormControlLabel, Checkbox, Box } from '@material-ui/core';
import { FaCcMastercard, FaCcVisa } from 'react-icons/fa';

const PaymentMethod = ({ method, checked, onChange }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          value={method}
          checked={checked}
          onChange={onChange}
        />
      }
      label={
        <Box display="flex" alignItems="center">
          {method === 'Mastercard' && <FaCcMastercard style={{ marginRight: '8px' }} />}
          {method === 'Visa' && <FaCcVisa style={{ marginRight: '8px' }} />}
          {method}
        </Box>
      }
    />
  );
};

export default PaymentMethod;
